(function () {
  class Loan extends Common {
    constructor() {
      super()
    };

    configure() {
      // BY ID
      this.ui.companyID = "#loan_company_id";
      this.ui.phoneImei = "#loan_details_phone_loan_attributes_imei";
      this.ui.phoneImeiConfirmation = "#loan_details_phone_loan_attributes_imei_confirmation";
      this.ui.customerId = "#loan_customer_attributes_id";
      this.ui.customerFirstName = "#loan_customer_attributes_first_name";
      this.ui.customerSecondName = "#loan_customer_attributes_second_name";
      this.ui.customerFirstLastName = "#loan_customer_attributes_first_last_name";
      this.ui.customerSecondLastName = "#loan_customer_attributes_second_last_name";
      this.ui.customerEmail = "#loan_customer_detail_attributes_email";
      this.ui.customerReferenceName1 = "#loan_customer_detail_attributes_first_fullname_reference";
      this.ui.customerReferenceNumber1 = "#loan_customer_detail_attributes_first_number_reference";
      this.ui.customerReferenceName2 = "#loan_customer_detail_attributes_second_fullname_reference";
      this.ui.customerReferenceNumber2 = "#loan_customer_detail_attributes_second_number_reference";
      this.ui.customerIdNumber = "#loan_customer_attributes_id_number";
      this.ui.customerIdNumberConfirmation = "#loan_customer_attributes_id_number_confirmation";
      this.ui.customerBirthDate = "#loan_customer_attributes_birth_date";
      this.ui.customerGender = "#loan_customer_attributes_gender";
      this.ui.customerProvinceId = "#loan_customer_detail_attributes_province_id";
      this.ui.customerDistrictId = "#loan_customer_detail_attributes_district_id";
      this.ui.customerJurisdictionId = "#loan_customer_detail_attributes_jurisdiction_id";
      this.ui.customerStreet = "#loan_customer_detail_attributes_address_street";
      this.ui.customerHomeName = "#loan_customer_detail_attributes_address_house_name";
      this.ui.customerHouseNumber = "#loan_customer_detail_attributes_address_house_number";
      this.ui.customerMaritalStatus = "#loan_customer_detail_attributes_marital_status";
      this.ui.customerPhotoFront = "#loan_customer_detail_attributes_id_photo_front";
      this.ui.customerPhotoBack = "#loan_customer_detail_attributes_id_photo_back";
      this.ui.customerPhotoWithUser = "#loan_customer_detail_attributes_id_photo_with_user";
      this.ui.customerCellphone = "#loan_customer_detail_attributes_cellphone_number";
      this.ui.customerEconomicGroupId = "#loan_customer_detail_attributes_economic_group_id";
      this.ui.customerSwtich1 = "#customSwitch1";
      this.ui.customerSwtich2 = "#customSwitch2";
      this.ui.customerSwtich3 = "#customSwitch3";

      this.ui.deviceBrandId = "#device_brand_id";
      this.ui.devicePrice = "#loan_details_phone_loan_attributes_device_price";
      this.ui.percentageCompost = "input[name*='[percentage_compost]']:checked";
      this.ui.deviceModelId = "#loan_details_phone_loan_attributes_device_model_id";
      this.ui.paymentPeriodId = "input[name*='[payment_period_id]']:checked, input[name*='[payment_period]']:checked";
      this.ui.paymentType = "input[name='loan[payment_type]']:checked";
      this.ui.loanAmount = "#loan_loan_amount";
      this.ui.result = "#result";
      this.ui.letterPreview = "#letter_preview";
      this.ui.loanCustomerNationality = "#loan_customer_attributes_nationality";

      // BY Class
      this.ui.jsRootWeb = ".js-root-web";
      this.ui.jsCompany = ".js-company";
      this.ui.jsValidateDNI = ".js-validate-dni";
      this.ui.jsDevicePriceITBMS = ".js-device-price-itbms";
      this.ui.jsValidateUser = ".js-validate-user";

      // Step 4
      this.ui.jsDevice = ".js-device";
      this.ui.jsFirstPaymentDate = ".js-first-payment-date";
      this.ui.jsLastPaymentDate = ".js-last-payment-date";
      this.ui.jsLetterQuantity = ".js-letter-quantity";
      this.ui.jsPaymentType = ".js-payment-type";
      this.ui.jsLetterPreview = ".js-letter-preview";

      this.ui.jsDevicePrice = ".js-device-price";
      this.ui.jsITBMS = ".js-itbms";
      this.ui.jsTotal = ".js-total";
      this.ui.jsInitialCompost = ".js-initial-compost";
      this.ui.jsLoanAmount = ".js-loan-amount";
      this.ui.jsPaymentLetter = ".js-payment-letter";
      this.ui.jsInterestCost = ".js-interest-cost";
      this.ui.jsTotalPayment = ".js-total-payment";
      this.ui.jsNextButton = "button.step_button--next";
      this.ui.jsFrontPhonto = ".js-front-photo";
      this.ui.jsBackPhoto = ".js-back-photo";
      this.ui.jsCustomerPhoto = ".js-customer-photo";
      this.ui.jsFormMovileLoan = ".form-movile-loan";
      this.ui.jsInitialCompostPreview = ".js-initial-compost-preview";
    };

    loadComponents() {
      this.configure();

      $("input[name*='[percentage_compost]']").first().prop('checked', true);
      this.needFormValidation();
      this.textUpperCase();
      this.loadSelect2();
      this.loadSelect2Cascade("#device_brand_id", "#loan_details_phone_loan_attributes_device_model_id");
      this.loadSelect2Cascade("#loan_customer_detail_attributes_province_id", "#loan_customer_detail_attributes_district_id");
      this.loadSelect2Cascade("#loan_customer_detail_attributes_district_id", "#loan_customer_detail_attributes_jurisdiction_id");
      this.loadBrandsMaxPrice();
      this.onlyLetter();
      this.onlyNumber();
      this.maskedNumber();
      this.calculateSum();
      this.validateDNI();
      this.validateUser(true);
      this.prepareLoanDescription();
      this.changeLabelOnNationality();
      this.wizardStepValidation();
      this.addValidatorDevicePrice();
      $(this.ui.devicePrice).blur();

      $("#loan_customer_detail_attributes_cellphone_number").rules("add", {
        validateCellphone: { loan_id: "true" }
      });
    };

    loadAPCStep() {
      const operation = this;

      this.configure();
      this.loadDataCompanyID();
      this.onlyLetter();
      this.onlyNumber();
      this.textUpperCase();
      this.needFormValidation();
      this.maskedNumber();
      this.loadSelect2Cascade("#device_brand_id", "#loan_details_phone_loan_attributes_device_model_id");
      this.validateDNI();
      this.validateUser(false);
      this.addValidatorDevicePrice();
      this.calculateSumStepAPC();
      this.changeLabelOnNationality();
      this.loadValidateBirthDate();

      $("input[name*='[percentage_compost]']").first().prop('checked', true);

      $("#loan_message_dni_confirmation").rules('add', {
        equalTo: "#loan_customer_attributes_id_number"
      });

      $("#loan_message_cellphone").rules("add", {
        validateCellphone: { loan_id: "true" }
      });

      $(operation.ui.devicePrice).on('blur', function () {
        var price_without_itbms = operation.calculateDevicePriceWithoutITBMS();
        $(operation.ui.jsDevicePriceITBMS).text(price_without_itbms.format());
      })
    };

    loadDataCompanyID() {
      const operation = this;
      var options = $(operation.ui.companyID).find('option');

      options.each(function (idx, element) {
        var initialPayment = element.dataset["initialPayment"];
        $(this).data('initial-payment', initialPayment);
        $(element).removeAttr("data-initial-payment");
      });
    };

    loadBrandsMaxPrice() {
      const operation = this;

      $(operation.ui.deviceBrandId).on('change', function () {
        var selected = parseInt($(this).find('option:selected').val());
        var devices = $('.js-root-web').data('approvedDevices');
        if (Object.keys(devices).length !== 0) {
          var device = devices.filter(function (device) {
            return device.brands.includes(selected) || device.brands.length === 0;
          }).reduce(function (max, device) {
            return device.max_amount > max.max_amount ? device : max;
          });

          $(this).data('query', '');
          $(this).data('query', JSON.stringify({ max_price: device['max_amount'] }));
        };
      });
    };

    calculateDevicePriceWithoutITBMS() {
      const itbms = Number($(this.ui.jsRootWeb).data('tax') / 100) + 1
      var device_price = Number($(this.ui.devicePrice).val().replaceAll(',', ''));
      var device_price_without_itbms = (device_price / itbms).toFixed(2);
      return Number(device_price_without_itbms);
    };

    calculateITBMS() {
      const operation = this;
      var country_tax = Number($(this.ui.jsRootWeb).data('tax') / 100)
      var device_price = operation.calculateDevicePriceWithoutITBMS();
      var itbms = (device_price * country_tax).toFixed(2);
      return Number(itbms);
    }

    calculateDevicePrice() {
      var device_price = Number($(this.ui.devicePrice).val().replaceAll(',', '')).toFixed(2);
      return Number(device_price);
    };

    calculateLoanAmount() {
      const operation = this;
      var price = operation.calculateDevicePrice();
      var percentage_compost = Number($(operation.ui.percentageCompost).val());
      var loan_amount = (price - (price * (percentage_compost / 100))).toFixed(2);

      return Number(loan_amount);
    };

    calculateInitialCompost() {
      const operation = this;
      var price = operation.calculateDevicePrice();
      var percentage_compost = Number($(operation.ui.percentageCompost).val());
      var initial_compost = (price * (percentage_compost / 100)).toFixed(2);

      $(operation.ui.jsInitialCompostPreview).text(Number(initial_compost).format());
    };

    calculateSum() {
      const operation = this;

      $(operation.ui.devicePrice).on('blur', function () {
        var price_without_itbms = operation.calculateDevicePriceWithoutITBMS()
        var loan_amount = operation.calculateLoanAmount();

        $(operation.ui.result).val(loan_amount);
        $(operation.ui.loanAmount).val(loan_amount);
        $(operation.ui.jsDevicePriceITBMS).text(price_without_itbms.format());
        operation.calculateLetterPreview();
        operation.calculateInitialCompost();
      })

      $(document).on('input', '.custom-percentage-compost', function () {
        var loan_amount = operation.calculateLoanAmount();
        $(operation.ui.result).val(loan_amount);
        $(operation.ui.loanAmount).val(loan_amount);
        operation.calculateLetterPreview();
        operation.calculateInitialCompost();
      });

      $(document).on('input', '#loan_payment_period_id', function () {
        operation.calculateLetterPreview();
        operation.calculateInitialCompost();
      })

      $(operation.ui.customerBirthDate).on('blur', function () {
        operation.calculateLetterPreview();
        operation.calculateInitialCompost();
      })

      $(operation.ui.customerGender).on('blur', function () {
        operation.calculateLetterPreview();
        operation.calculateInitialCompost();
      })
    };

    calculateSumStepAPC() {
      const operation = this;

      $(operation.ui.devicePrice).on('blur', function () {
        var loan_amount = operation.calculateLoanAmount();
        $(operation.ui.result).val(loan_amount);
        operation.calculateInitialCompost();
        operation.calculateLetterPreview();
      })

      $(document).on('input', '.custom-percentage-compost', function () {
        var loan_amount = operation.calculateLoanAmount();
        $(operation.ui.result).val(loan_amount);
        operation.calculateInitialCompost();
        operation.calculateLetterPreview();
      });

      $(document).on('input', '#loan_payment_period_id', function () {
        operation.calculateLetterPreview();
        operation.calculateInitialCompost();
      })

      $(operation.ui.customerBirthDate).on('blur', function () {
        operation.calculateLetterPreview();
        operation.calculateInitialCompost();
      })

      $(operation.ui.customerGender).on('blur', function () {
        operation.calculateLetterPreview();
        operation.calculateInitialCompost();
      })
    };

    validateDNI() {
      const operation = this;

      $(operation.ui.jsValidateDNI).on('blur', function () {
        var dni = this.value;
        $.ajax({
          method: 'GET',
          url: '/js_validate_unique_loan',
          data: { dni: dni }
        }).done(function (data) {
          console.log('done');
          if (typeof data !== 'undefined') {
            if (data.status) {
              bootbox.alert({
                message: data.messages,
              });
            }
          }
        }).fail(function () {
          console.log("error");
        });
      });
    };

    validateUser(allFields) {
      const operation = this;
      $(operation.ui.jsValidateUser).on('blur', function () {
        var dni = this.value;
        operation.resetFillForm(allFields);

        $.ajax({
          method: 'GET',
          url: '/js_load_existing_customer',
          data: { dni: dni }
        }).done(function (data) {
          var customer = data.result['customer'];
          var customerDetail = data.result['customer_detail'];
          operation.fillForm(allFields, customer, customerDetail);
        }).fail(function () {
        });
      });
    };

    resetFillForm(allFields) {
      const operation = this;
      $(operation.ui.customerId).val("");
      $(operation.ui.customerFirstName).val("");
      $(operation.ui.customerFirstLastName).val("");
      $(operation.ui.customerBirthDate).val("");
      $(operation.ui.customerGender).val("");

      if (allFields) {
        $(operation.ui.customerSecondName).val("");
        $(operation.ui.customerSecondLastName).val("");
        $(operation.ui.customerEmail).val("");
        $(operation.ui.customerReferenceName1).val("");
        $(operation.ui.customerReferenceNumber1).val("");
        $(operation.ui.customerReferenceName2).val("");
        $(operation.ui.customerReferenceNumber2).val("");
        $(operation.ui.customerProvinceId).val(null).trigger('change');
        $(operation.ui.customerDistrictId).val(null).trigger('change');
        $(operation.ui.customerJurisdictionId).val(null).trigger('change');
        $(operation.ui.customerStreet).val("");
        $(operation.ui.customerHomeName).val("");
        $(operation.ui.customerHouseNumber).val("");
        $(operation.ui.customerMaritalStatus).val("");
        $(operation.ui.customerEconomicGroupId).val(null).trigger('change');
        $(operation.ui.customerCellphone).val("");
      }
    };

    fillForm(allFields, customer, customerDetail) {
      const operation = this;
      $(operation.ui.customerId).val(customer['id']);
      $(operation.ui.customerFirstName).val(customer['first_name']);
      $(operation.ui.customerFirstLastName).val(customer['first_last_name']);
      $(operation.ui.customerBirthDate).val(customer['birth_date']);
      $(operation.ui.customerGender).val(customer['gender']);

      if (allFields) {
        $(operation.ui.customerSecondName).val(customer['second_name']);
        $(operation.ui.customerSecondLastName).val(customer['second_last_name']);

        if (customerDetail) {
          $(operation.ui.customerEmail).val(customerDetail['email']);
          $(operation.ui.customerReferenceName1).val(customerDetail['first_fullname_reference']);
          $(operation.ui.customerReferenceNumber1).val(customerDetail['first_number_reference']);
          $(operation.ui.customerReferenceName2).val(customerDetail['second_fullname_reference']);
          $(operation.ui.customerReferenceNumber2).val(customerDetail['second_number_reference']);
          $(operation.ui.customerProvinceId).val(customerDetail['province_id']).trigger('change');
          operation.addOptionSelect2(operation.ui.customerDistrictId, customerDetail['district_option_for_select']);
          operation.addOptionSelect2(operation.ui.customerJurisdictionId, customerDetail['jurisdiction_option_for_select']);
          $(operation.ui.customerStreet).val(customerDetail['address_street']);
          $(operation.ui.customerHomeName).val(customerDetail['address_house_name']);
          $(operation.ui.customerHouseNumber).val(customerDetail['address_house_number']);
          $(operation.ui.customerMaritalStatus).val(customerDetail['marital_status']);
          $(operation.ui.customerEconomicGroupId).val(customerDetail['economic_group_id']).trigger('change');
          $(operation.ui.customerCellphone).val(customerDetail['cellphone_number']);
        };
      };
    };

    prepareLoanDescription() {
      const operation = this;
      $(operation.ui.jsNextButton).on('click', function () {
        var interest = operation.getInterest();
        // var payment_type = ($(operation.ui.paymentType).val() == "biweekly") ? "Quincenal" : "Mensual";
        var payment_type = "Quincenal"
        var period_quantity = operation.getPaymentPeriod();
        // var letter_quantity = (payment_type == "Quincenal") ? (2 * period_quantity) : period_quantity
        var letter_quantity = (2 * period_quantity);
        var initial_payment_date = moment(new Date()).add(14, 'days');
        var first_payment = initial_payment_date.format("DD/MM/YYYY");
        var item = new CalculatorLoanDates(initial_payment_date);
        var last_payment = moment(item.calculateBiweekly(letter_quantity)).format("DD/MM/YYYY");
        var device_price = operation.calculateDevicePrice();
        var loan_amount = operation.calculateLoanAmount();
        var device_price_without_itbms = operation.calculateDevicePriceWithoutITBMS();
        var itbms = operation.calculateITBMS();
        var interest_cost = interest * loan_amount * period_quantity;
        var total_payment = loan_amount + interest_cost;
        var letter_payment = total_payment / letter_quantity;

        $(operation.ui.jsDevice).text($(operation.ui.deviceModelId).find('option:selected').text());
        $(operation.ui.jsFirstPaymentDate).text(first_payment);
        $(operation.ui.jsLastPaymentDate).text(last_payment);
        $(operation.ui.jsLetterQuantity).text(letter_quantity);
        $(operation.ui.jsPaymentType).text(payment_type);

        // Calculate
        $(operation.ui.jsDevicePrice).text(device_price_without_itbms.format());
        $(operation.ui.jsITBMS).text(itbms.format());
        $(operation.ui.jsTotal).text((device_price * 1.00).format());
        $(operation.ui.jsInitialCompost).text(((device_price - loan_amount) * 1.00).format());
        $(operation.ui.jsLoanAmount).text(loan_amount.format());
        //$(operation.ui.jsInterestCost).text(interest_cost.toFixed(2));
        //$(operation.ui.jsTotalPayment).text(total_payment.toFixed(2));
        $(operation.ui.jsPaymentLetter).text(Math.ceil(letter_payment).toFixed(2));
      });
    };

    calculateLetterPreview() {
      const operation = this;
      var interest = operation.getInterest();
      var period_quantity = operation.getPaymentPeriod() || 4;
      var letter_quantity = (2 * period_quantity);
      var loan_amount = Number($(operation.ui.result).val().replaceAll(',', ''));
      var interest_cost = interest * loan_amount * period_quantity;
      var total_payment = loan_amount + interest_cost;
      var letter_payment = total_payment / letter_quantity;

      $(operation.ui.jsLetterPreview).text(Math.ceil(letter_payment).toFixed(2));
      $(operation.ui.letterPreview).val(Math.ceil(letter_payment).toFixed(2));
    };

    changeLabelOnNationality() {
      const operation = this;
      $(operation.ui.loanCustomerNationality).on('change', function () {
        var selected = $(this).val();

        operation.getLabelNationality(selected);

        if ($(operation.ui.customerIdNumber).val() != "") {
          $(operation.ui.customerIdNumber).valid();
        };
      });
    };

    getLabelNationality(selected) {
      const operation = this;

      if (selected == "national") {
        $(operation.ui.customerIdNumber).addClass("formatDNI");
        $(operation.ui.customerIdNumber).rules('add', { formatDNI: true });
        $(operation.ui.jsFrontPhonto).text(i18n.t('activerecord.attributes.customer_detail.id_photo_front'))
        $(operation.ui.jsBackPhoto).text(i18n.t('activerecord.attributes.customer_detail.id_photo_back'))
        $(operation.ui.jsCustomerPhoto).text(i18n.t('activerecord.attributes.customer_detail.id_photo_with_user'))
      } else {
        $(operation.ui.customerIdNumber).removeClass("formatDNI");
        $(operation.ui.customerIdNumber).rules("remove", "formatDNI");
        $(operation.ui.jsFrontPhonto).text("Foto del pasaporte")
        $(operation.ui.jsBackPhoto).text("Foto del carné de residencia (Un año de vigencia mínimo)")
        $(operation.ui.jsCustomerPhoto).text("Foto del usuario con el carné de residencia")
      }
    };

    wizardStepValidation() {
      const operation = this;

      $(operation.ui.phoneImeiConfirmation).rules('add', {
        equalTo: operation.ui.phoneImei
      });

      $(operation.ui.customerIdNumberConfirmation).rules('add', {
        equalTo: operation.ui.customerIdNumber
      });

      $(operation.ui.customerBirthDate).rules('add', {
        maxAge: 75,
        minAge: 18
      });

      $(operation.ui.jsFormMovileLoan).on('click', function (e) {
        let element = e.target;
        let isButtonNext = element.classList.contains('step_button--next');
        let isButtonBack = element.classList.contains('step_button--back');
        let current_step = element.dataset.step;

        if (current_step == 1) {
          var validForm = !$(operation.ui.customerIdNumber).valid() || !$(operation.ui.customerIdNumberConfirmation).valid() || !$(operation.ui.deviceModelId).valid() ||
            !$(operation.ui.phoneImei).valid() || !$(operation.ui.phoneImeiConfirmation).valid() || !$(operation.ui.devicePrice).valid() ||
            !$(operation.ui.customerBirthDate).valid() || !$(operation.ui.customerGender).valid();

          if (validForm) {
            $(operation.ui.customerIdNumber).valid();
            $(operation.ui.customerIdNumberConfirmation).valid()
            $(operation.ui.deviceModelId).valid();
            $(operation.ui.phoneImei).valid();
            $(operation.ui.phoneImeiConfirmation).valid();
            $(operation.ui.devicePrice).valid();
            $(operation.ui.customerBirthDate).valid();
            $(operation.ui.customerGender).valid();
            $(".js-need-validation").find(":input.is-invalid:first").focus();
            return;
          }
        } else if (current_step == 2) {
          var validForm = !$(operation.ui.customerFirstName).valid() || !$(operation.ui.customerFirstLastName).valid() || !$(operation.ui.customerEmail).valid() ||
            !$(operation.ui.customerReferenceName1).valid() || !$(operation.ui.customerReferenceNumber1).valid() || !$(operation.ui.customerReferenceName2).valid() ||
            !$(operation.ui.customerReferenceNumber2).valid() || !$(operation.ui.customerProvinceId).valid() || !$(operation.ui.customerDistrictId).valid() ||
            !$(operation.ui.customerStreet).valid() || !$(operation.ui.customerHomeName).valid() || !$(operation.ui.customerHouseNumber).valid() ||
            !$(operation.ui.customerEconomicGroupId).valid()

          if (document.documentElement.lang != 'es_nic') {
            validForm = validForm || !$(operation.ui.customerJurisdictionId).valid()
          };

          if (validForm) {
            $(operation.ui.customerFirstName).valid();
            $(operation.ui.customerFirstLastName).valid();
            $(operation.ui.customerEmail).valid();
            $(operation.ui.customerReferenceName1).valid();
            $(operation.ui.customerReferenceNumber1).valid();
            $(operation.ui.customerReferenceName2).valid();
            $(operation.ui.customerReferenceNumber2).valid();
            $(operation.ui.customerProvinceId).valid();
            $(operation.ui.customerDistrictId).valid();
            if (document.documentElement.lang != 'es_nic') {
              $(operation.ui.customerJurisdictionId).valid();
            };
            $(operation.ui.customerStreet).valid();
            $(operation.ui.customerHomeName).valid();
            $(operation.ui.customerHouseNumber).valid();
            $(operation.ui.customerEconomicGroupId).valid();
            $(".js-need-validation").find(":input.is-invalid:first").focus();
            return;
          }
        } else if (current_step == 3) {
          var validForm = !$(operation.ui.customerPhotoFront).valid() || !$(operation.ui.customerPhotoBack).valid() ||
            !$(operation.ui.customerPhotoWithUser).valid() || !$(operation.ui.customerCellphone).valid()

          if (validForm) {
            $(operation.ui.customerPhotoFront).valid();
            $(operation.ui.customerPhotoBack).valid();
            $(operation.ui.customerPhotoWithUser).valid();
            $(operation.ui.customerCellphone).valid();
            $(".js-need-validation").find(":input.is-invalid:first").focus();
            return;
          }

          if (!$("#customSwitch1").prop("checked") || !$("#customSwitch2").prop("checked") || !$("#customSwitch3").prop("checked")) {
            var message = $("#customSwitchesMessage").data("message");
            bootbox.alert({
              message: message
            });
            return;
          }
        }

        if (isButtonNext || isButtonBack) {
          let currentStep = document.getElementById('step-' + element.dataset.step);
          let jumpStep = document.getElementById('step-' + element.dataset.to_step);
          currentStep.classList.remove('active');
          jumpStep.classList.add('active');
          if (isButtonNext) {
            currentStep.classList.add('to-left');
          } else {
            jumpStep.classList.remove('to-left');
          }
          currentStep.classList.add('inactive');
          jumpStep.classList.remove('inactive');
        }
      });
    };

    calculateAge() {
      const operation = this;
      var birthDate = $(operation.ui.customerBirthDate).val();
      var birthDateDay = new Date(birthDate + "T00:00:00");
      var today = new Date();

      if ($(operation.ui.devicePrice).val() != "" || $(operation.ui.devicePrice).val() != 0) {
        var validForm = !$(operation.ui.customerBirthDate).valid() || !$(operation.ui.customerGender).valid();

        if (validForm) {
          $(operation.ui.customerBirthDate).valid();
          $(operation.ui.customerGender).valid();
          $(".js-need-validation").find(":input.is-invalid:first").focus();
          return;
        };
      };

      return parseInt((today - birthDateDay) / (1000 * 60 * 60 * 24 * 365));
    };

    getPaymentPeriod() {
      const operation = this;
      const period = $(operation.ui.jsRootWeb).data('payment-periods'); //{ 1: 4, 2: 6, 3: 9 };
      result = period.filter(function (item) {
        return item.id === Number($(operation.ui.paymentPeriodId).val());
      });

      return result[0].value;
    };

    getInterest() {
      const operation = this;
      var age = operation.calculateAge();
      var gender = $(operation.ui.customerGender).find("option:selected").val();
      var paymentPeriod = operation.getPaymentPeriod();
      var percentageCompost = Number($(operation.ui.percentageCompost).val());

      if (typeof $(operation.ui.jsRootWeb).data('interest-rate')[paymentPeriod] === 'undefined' || typeof $(operation.ui.jsRootWeb).data('interest-rate')[paymentPeriod][percentageCompost] === 'undefined') {
        Swal.fire({
          icon: "error",
          html: i18n.t('bootbox.no_inital_payment_html'),
          confirmButtonText: 'Aceptar'
        });
        // TODO: Puede ocasionar loop si el primero no esta configurado.
        $("input[name*='[payment_period_id]'], input[name*='[payment_period]']").first().prop('checked', true);
        setTimeout(function () {
          $(operation.ui.devicePrice).blur();
        }, 50);

        return false;
      } else {
        var interest = $(operation.ui.jsRootWeb).data('interest-rate')[paymentPeriod][percentageCompost];
      }

      if (gender == "female" && age >= gon.global.female_retirement_age || gender == "male" && age >= gon.global.male_retirement_age) {
        return ((interest - interest * 0.15) / 12 / 100);
      } else {
        return (interest / 12 / 100);
      }
    };

    modalMessages(element, url, msg_type) {
      const operation = this;
      $(element).on('click', function () {
        var validForm = !$(operation.ui.customerCellphone).valid();

        if (validForm) {
          $(operation.ui.customerCellphone).valid();
          $(".js-need-validation").find(":input.is-invalid:first").focus();
          $(this).prop('checked', false);

          return;
        }

        if ($(this).prop('checked')) {
          var first_name = $(operation.ui.customerFirstName).val();
          var last_name = $(operation.ui.customerFirstLastName).val();

          $.ajax({
            method: "POST",
            url: url,
            dataType: 'script',
            data: {
              loan_message: {
                msg_type: msg_type,
                dni: $(operation.ui.customerIdNumber).val(),
                cellphone: $(operation.ui.customerCellphone).val(),
                fullname: `${first_name} ${last_name}`
              }
            }
          }).done(function () {
            console.log("Done!");
          }).fail(function () {
            console.log("Error");
          });
        }
      });
    };

    addValidatorDevicePrice() {
      const operation = this;

      $(operation.ui.deviceModelId).on('change', function () {
        var selected = $(this).find('option:selected').val()

        $(operation.ui.devicePrice).val("");
        $(operation.ui.devicePrice).blur();

        if (selected != "") {
          $.ajax({
            method: "GET",
            url: `/device_models/${selected}.json`
          }).done(function (data) {
            var max_price = parseFloat(data.max_price);

            $(operation.ui.devicePrice).rules('add', {
              maxPrice: max_price,
              messages: {
                maxPrice: `El precio máximo de venta es $ ${max_price.format()}.`
              }
            });
            console.log("Done!");
          }).fail(function () {
            console.log("Error");
          });
        }
      });
    };
  };

  window.Loan = Loan;
})();
